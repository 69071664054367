// Generated by Framer (8563fcd)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["kIPTCkggt", "A8bIqkQtR", "czmIsmFB6"];

const variantClassNames = {A8bIqkQtR: "framer-v-1plw9vx", czmIsmFB6: "framer-v-1iwsptb", kIPTCkggt: "framer-v-1itdcuo"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {Desktop: "kIPTCkggt", Phone: "czmIsmFB6", Tablet: "A8bIqkQtR"};

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "kIPTCkggt", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "kIPTCkggt", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-6WfBb", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.section {...restProps} className={cx("framer-1itdcuo", className)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"kIPTCkggt"} ref={ref} style={{backgroundColor: "rgb(255, 255, 255)", ...style}} {...addPropertyOverrides({A8bIqkQtR: {"data-framer-name": "Tablet"}, czmIsmFB6: {"data-framer-name": "Phone"}}, baseVariant, gestureVariant)}/></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-6WfBb [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-6WfBb .framer-17u89j7 { display: block; }", ".framer-6WfBb .framer-1itdcuo { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: flex-start; min-height: 373px; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 1200px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-6WfBb .framer-1itdcuo { gap: 0px; } .framer-6WfBb .framer-1itdcuo > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-6WfBb .framer-1itdcuo > :first-child { margin-top: 0px; } .framer-6WfBb .framer-1itdcuo > :last-child { margin-bottom: 0px; } }", ".framer-6WfBb.framer-v-1plw9vx .framer-1itdcuo { width: 810px; }", ".framer-6WfBb.framer-v-1iwsptb .framer-1itdcuo { min-height: 833px; width: 390px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 373
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"A8bIqkQtR":{"layout":["fixed","auto"]},"czmIsmFB6":{"layout":["fixed","auto"]}}}
 */
const FramerLhERbWbGM: React.ComponentType<Props> = withCSS(Component, css, "framer-6WfBb") as typeof Component;
export default FramerLhERbWbGM;

FramerLhERbWbGM.displayName = "Footer / Justified / 3";

FramerLhERbWbGM.defaultProps = {height: 373, width: 1200};

addPropertyControls(FramerLhERbWbGM, {variant: {options: ["kIPTCkggt", "A8bIqkQtR", "czmIsmFB6"], optionTitles: ["Desktop", "Tablet", "Phone"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerLhERbWbGM, [])